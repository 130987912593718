import React from "react";
import clsx from "clsx";
import { ServiceCard } from "@molecules";
import { Container, Text } from "@atoms";

const ServiceGrid = ({
  bgColor,
  copy,
  paddingBottom,
  paddingTop,
  services,
  className: _className,
}) => {
  return (
    <section
      className={clsx(
        "relative pb-16 pt-16",
        {
          "md:pt-32": paddingTop,
          "md:pb-32": paddingBottom,
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white",
        },
        _className
      )}
    >
      <Container className="relative z-20">
        <div className="flex flex-col items-center">
          {copy && (
            <div className="mx-auto max-w-3xl">
              <Text
                richText
                className={clsx(
                  "text-center prose-p:text-lg lg:prose-p:text-xl",
                  {
                    "prose-dark": bgColor === "midnight",
                  }
                )}
              >
                {copy}
              </Text>
            </div>
          )}
          {services && (
            <div className="mt-8 md:mt-16">
              <div className="flex flex-wrap justify-center gap-8">
                {services.map((service, i) => {
                  return (
                    <ServiceCard
                      {...service}
                      key={service.uid}
                      className="w-full min-w-[16rem] border-mint duration-300 hover:border-carnation sm:flex-[0_1_calc(50%-1rem)] md:flex-[0_1_calc(33.33%-1.34rem)]"
                      color={bgColor}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

ServiceGrid.defaultProps = {
  overlap: false,
};

export default ServiceGrid;
